import { template as template_9a42e4a6c4a141dd99ebd6328b02b54e } from "@ember/template-compiler";
const FKLabel = template_9a42e4a6c4a141dd99ebd6328b02b54e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
