import { template as template_73d5edcfbd96433cb4f99e6f8eeb6328 } from "@ember/template-compiler";
const WelcomeHeader = template_73d5edcfbd96433cb4f99e6f8eeb6328(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
