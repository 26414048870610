import { template as template_971f81212e48471db5225e1d0448431b } from "@ember/template-compiler";
const FKText = template_971f81212e48471db5225e1d0448431b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
